import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Raschin
Cyber Mission
The physical environmental boundary of a neighborhood is not usually a definite border but a distance from the center of a neighborhood. The center of the neighborhood in itself can consist of several landmarks. In real space a landmark is a place that for historical or cultural reasons attracts the attention of people.`}</p>
    <p><img parentName="p" {...{
        "src": "http://raschin.com/blog/wp-content/uploads/2010/11/missiontoblog.jpg",
        "alt": "mission_blog"
      }}></img></p>
    <p>{`Cyber Mission explores the difference between distances in a physical neighborhood such as mission district in San Francisco and distances between information of that neighborhood in Wikipedia article. It uses semantic relevancy of articles (based on Wikipedia miner service) as a metric to visualize their relations with each other.
`}<img parentName="p" {...{
        "src": "http://raschin.com/blog/wp-content/uploads/2010/11/revelel.jpg",
        "alt": "wikiminer"
      }}></img></p>
    <p>{`In this project the vertical line in the middle represents the main article and dots around that represent the outgoing and incoming links to that article. ( On the right side of the line are outgoing links and on the left side of the line are incoming links). The more relevant the linked article is to the main article, the closer it is to the middle line on the x-axis.`}</p>
    <p><img parentName="p" {...{
        "src": "http://raschin.com/blog/wp-content/uploads/2010/11/cybermission.jpg",
        "alt": "cybermission"
      }}></img></p>
    <p>{`The green dots are the common links in outgoing and incoming categories. The line that passes through these green dots represents the boundary of the neighborhood.
`}<img parentName="p" {...{
        "src": "http://raschin.com/blog/wp-content/uploads/2010/11/data1-1024x379.jpg",
        "alt": "cybermission_1"
      }}></img>{`
`}<img parentName="p" {...{
        "src": "http://raschin.com/blog/wp-content/uploads/2010/11/data3-1024x334.jpg",
        "alt": "cybermission_2"
      }}></img>{`
`}<img parentName="p" {...{
        "src": "http://raschin.com/blog/wp-content/uploads/2010/11/data4.jpg",
        "alt": "cybermission_3"
      }}></img>{`
`}<img parentName="p" {...{
        "src": "http://raschin.com/blog/wp-content/uploads/2010/11/data5.jpg",
        "alt": "cybermission_4"
      }}></img></p>
    <p>{`2020 by Raschin.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      